import React from 'react'
import LayoutGlobal from '../components/LayoutGlobal'
import Seo from '../components/Seo'
import ToggleVisit from '../components/ToggleVisit'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import { FaCheckSquare, FaRegSquare, FaFileAlt } from "react-icons/fa"

export default function Wizyta() {
  return (
    <LayoutGlobal>

      <Seo 
        title="Pierwsza wizyta"
        description="Przygotuj się do pierwszej wizyty w Studio - krok po kroku"
        url="https://www.fizjopersonalny.pl/wizyta"
      />

      <section>
        <div className='visit_box visit_margin_intro'>
          <h1>Pierwsza wizyta w Studio</h1>
          <div className='visit_grid'>
            <div className='visit_intro'>
             <p>Cieszę się, że już niedługo się zobaczymy!</p>
             <p>Zdaję sobie sprawę, że pierwsza wizyta w nowym miejscu może byc dla Ciebie wyzwaniem. Dlatego, aby lepiej przygotować Cię na nasze pierwsze spotkanie, zapoznaj się z informacjami poniżej. Znajdziesz tu odpowiedzi na takie pytania jak:</p>
             <ul>
              <li><FaCheckSquare color="#fa4603"/>Co ze sobą zabrać</li>
              <li><FaCheckSquare color="#fa4603"/>Jak dotrzeć do Studia?</li>
              <li><FaCheckSquare color="#fa4603"/>Czego spodziewać się podczas wizyty?</li>
             </ul>
            </div>
            <div className='visit_image'>
              <StaticImage src="../images/wizyta.png" alt="Łukasz Kaleńczuk - FizjoPersonalny" />
            </div>
          </div>
        </div>
      </section>

      <div className='about_menu'>
        <Link to="#przygotowanie">przygotowanie</Link>
        <Link to="#lokalizacja">lokalizacja</Link>
        <Link to="#parking">parking</Link>
        <Link to="#platnosci">płatności</Link>
        <Link to="#lista-kontrolna">lista kontrolna</Link>
      </div>

      <section id="przygotowanie">
        <div className='visit_box visit_margin_body'>
          <h2>Przygotowanie</h2>
          <p>Podczas pierwszej wizyty przeprowadzę dokładny wywiad, przeanalizuję dostępną dokumentację medyczną oraz zbadam Twój sposób poruszania się. Na podstawie zebranych informacji zaproponuję plan działania, dopasowany do Twoich indywidualnych potrzeb, przyszłych celów i aktualnego poziomu sprawności.</p>
          <p>Czas jest cenny, dlatego przygotuj się proszę do pierwszej wizyty zgodnie z poniższymi wskazówki. Dzięki temu maksymalnie skorzystasz z czasu spędzonego w Studio.</p>

          <div className='visit_toggle'>
            <ToggleVisit title="Cel wizyty">
              <p>Zastanów się nad tym, jaki jest cel Twojej wizyty i zapisz go na kartce lub w telefonie. Świadomość tego, jakie korzyści ma Ci przynieść spotkanie ze mną jest bardzo ważna dla uzyskania satysfakcjonującego efektu.</p>
            </ToggleVisit>

            <ToggleVisit title="Dokumentacja medyczna">
              <p>Zbierz dostępną dokumentację medyczną (zdjęcia, płyty z badaniami, opisy, itp.) dotyczącą problemu z jakim do mnie przychodzisz. Dokumentacja pozwoli mi szerzej spojrzeć na Twój problem, ale nie jest wymagana.</p>
            </ToggleVisit>

            <ToggleVisit title="Strój sportowy">
              <p>Podczas konsultacji/fizjoterapii/treningu będziesz wykonywać różne ćwiczenia/badania/testy, których wynik jest bardziej wiarygodny, gdy Twoje ruchy nie są niczym skrępowane. Dlatego przygotuj wygodny strój na zmianę (spodenki, legginsy, top, koszulka, buty, itd.). Zabierz również ręcznik do otarcia potu. Jeżeli przychodzisz na masaż, strój nie jest wymagany.</p>
            </ToggleVisit>

            <ToggleVisit title="Wymagane zgody">
              <p>Chcąc zachować wysoki standard świadczonych usług oraz bezpieczne warunki pracy, jestem zobligowany do pozyskiwania i przetwarzania informacji wrażliwych, które dotyczą Twojej osoby. W związku z tym, proszę Cię o wypełnienie niezbędnych dokumentów. Zdaję sobie sprawę, że niektóre z pytań mocno ingerują w Twoją prywatność. Zapewniam Cię, że pozyskiwane informacje przechowuje w bezpiecznym miejscu, do którego posiadam wyłączny dostęp. Jeśli któreś z zagadnień jest dla Ciebie niejasne to z przyjemnością wytłumaczę Ci je podczas wizyty.</p>
              <ul>
                <li>
                  <FaFileAlt color="#fa4603"/><a href="/zgoda-na-realizacje-uslugi.pdf" target="_blank" rel="noreferrer">Zgoda na realizację usługi</a>
                </li>
                <li>
                <FaFileAlt color="#fa4603"/><a href="/zgoda-na-przetwarzanie-danych.pdf" target="_blank" rel="noreferrer">Zgoda na przetwarzanie danych osobowych</a>
                </li>
                <li>
                <FaFileAlt color="#fa4603"/><a href="/zgoda-przedstawiciela-ustawowego.pdf" target="_blank" rel="noreferrer">Zgoda przedstawiciela ustawowego na realizację usługi *</a>
                </li>
              </ul>
              <em>* Zgoda przedstawiciela ustawowego wymagana jest w przypadku, gdy Klient jest osobą małoletnią, całkowicie ubezwłasnowolnioną lub niezdolną do świadomego wyrażania zgody.</em>
            </ToggleVisit>

            <ToggleVisit title="Regulamin Studia">
              <p>Zapoznaj się również z zasadami współpracy, które obowiązują w Studio Medyczno-Treningowym.</p>
              <ul>
                <li>
                  <FaFileAlt color="#fa4603"/> <Link to="/regulamin-studia">Regulamin Studia</Link>
                </li>
              </ul>
            </ToggleVisit>

            <ToggleVisit title="W Studio">
              <p>Przyjdź do Studia 10 minut przed planowaną godziną rozpoczęcia wizyty. Na miejscu skorzystaj z szatni, aby rozebrać się z okrycia wierzchniego oraz przebrać się w strój sportowy. Jeśli będę zajęty, skorzystaj z poczekalnii, w której masz zapewniony dostęp do filtrowanej wody oraz szklanych bidonów. Dodatkowo napijesz się tu aromatycznej kawy lub herbaty.</p>
            </ToggleVisit>
          </div>

        </div>
      </section>

      <section id="lokalizacja">
        <div className='visit_box'>
          <h2>Lokalizacja</h2>
          <p>Studio Medyczno-Treningowe zlokalizowane jest w cichej okolicy, na terenie zamkniętego osiedla pod adresem Wrzeciono 63a/2b (01-950 Warszawa) w sąsiedztwie kampusu AWF oraz Szpitala Bielańskiego.</p>
          <p>Sprawdź dojazd za pośrednictwem <a href="https://goo.gl/maps/4H6dS7wtz8Ue3JSh9" target="_blank" rel="noreferrer">Google Maps</a>.</p>
          <StaticImage src="../images/lokalizacja.jpg" alt="FizjoPersonalny - Lokalizacja" />
          <br /><br />
          <p>Na miejscu kieruj się zgodnie ze wskazówkami umieszczonymi na tablicach informacyjnych (pierwsza - przy furtce; druga - w oknie klatki numer II). Na domofonie wybierz numer <b>102</b> i poczekaj na otwarcie furtki/drzwi. Skorzystaj również z lokalnej mapki umieszczonej poniżej.</p>
          <StaticImage src="../images/studio/klatka.jpg" alt="FizjoPersonalny - Klatka wejściowa" />
          <br /><br />
          <p>Planujesz przyjazd komunikacją miejską?<br />Zaplanuj podróż, uwzględniając najbliższe przystanki:</p>

          <div className='visit_toggle'>
            <ToggleVisit title="Metro">
              <p>Przystanek WAWRZYSZEW (linia M1)</p>
            </ToggleVisit>

            <ToggleVisit title="Tramwaj">
              <p>Przystanek UKSW (tramwaje linii numer: 1, 4, 6, 15, 17, 78)</p>
            </ToggleVisit>

            <ToggleVisit title="Autobus">
              <p>Przystanek UKSW (autobusy linii numer: 103, 121, 150, 181, 850)<br /> Przystanek Metro Wawrzyszew (autobusy linii numer: 156, 184, 303)</p>
            </ToggleVisit>
          </div>

        </div>
      </section>

      <section id="parking">
        <div className='visit_box visit_margin_body'>
          <h2>Parking</h2>
          <p>Decydując się na przyjazd samochodem, masz możliwość bezpłatnego parkowania. Miejsca postojowe znajdują się wzdłuż ulicy Wrzeciono oraz wewnątrz zamkniętego osiedla. Brama wjazdowa na osiedle znajduje się na przeciw wylotu ulicy Szegedyńskiej.</p>
          <StaticImage src="../images/studio/brama.jpg" alt="FizjoPersonalny - Parking" />
          <br /><br />
          <p>Brama wjazdowa otwierana jest zdalnie każdorazowo na bezpośrednią prośbę. Zadzwoń do mnie kiedy znajdziesz się przed bramą, abym mógł ją otworzyć.</p>
          <p>Zaparkuj samochód na dowolnym miejscu, stosując się do wskazówek umieszczonych na parkingowych tablicach informacyjnych. Samochód możesz pozostawić na parkingu wewnętrznym tylko na czas trwania wizyty w Studio.</p>
        </div>
      </section>

      <section id="platnosci">
        <div className='visit_box'>
          <h2>Płatności</h2>
          <p>Usługę realizowaną w Studio rozliczysz, wykorzystując następujące metody płatności:</p>

          <div className='visit_toggle'>
            <ToggleVisit title="Gotówka">
              <p>Płatność bezpośrednia dostępna tylko w Studio.</p>
            </ToggleVisit>

            <ToggleVisit title="Terminal / telefon / smartwatch">
              <p>Płatność bezpośrednia z wykorzystaniem terminala płatniczego, obsługująca:</p>
              <ul>
                <li>- płatność kartą (VISA, MasterCard, UnionPay, Diners Club, JCB);</li>
                <li>- płatność telefonem i smartwatch (ApplePay, GooglePay, SamsungPay);</li>
                <li>- płatność systemem BLIK.</li>
              </ul>
            </ToggleVisit>

            <ToggleVisit title="Przelew na konto">
              <p>Płatność z wykorzystaniem standardowego przelewu bankowego na konto firmowe. Przy korzystaniu z tej opcji, wykorzystaj poniższe dane:</p>
              <ul>
                <li>mBank: 63 1140 2004 0000 3302 7879 5415</li>
                <li>Łukasz Kaleńczuk - FizjoPersonalny</li>
                <li>ul. Wrzeciono 63a/28, 01-950 Warszawa</li>
              </ul>
            </ToggleVisit>
          </div>

          <br /><p>Aktualną wartość dostępnych usług znajdziesz w <Link to="/cennik">cenniku</Link>. Jeśli chcesz otrzymać potwierdzenie płatności w formie faktury lub paragonu z NIP to poinformuj mnie o tym PRZED dokonaniem płatności. Po zaksięgowaniu płatności na kasie fiskalnej, nie ma możliwości wystawienia faktury lub paragonu z NIP.</p>
        </div>
      </section>

      <section id="lista-kontrolna">
        <div className='visit_box visit_margin_body'>
          <h2>Lista kontrolna</h2>
          <p>Poniżej znajdziesz szybką listę kontrolną, która przygotuje Cię do pierwszej wizyty.</p>
          <ul>
            <li>
              <FaRegSquare color="#fa4603" size="1rem"/>Spisanie celów wizyty
            </li>
            <li>
              <FaRegSquare color="#fa4603" size="1rem"/>Spakowanie dostępnej dokumentacji medycznej
            </li>
            <li>
              <FaRegSquare color="#fa4603" size="1rem"/>Spakowanie stroju sportowego, butów oraz ręcznika
            </li>
            <li>
              <FaRegSquare color="#fa4603" size="1rem"/>Wydrukowanie i wypełnienie wymaganych zgód
            </li>
            <li>
              <FaRegSquare color="#fa4603" size="1rem"/>Przeczytanie Regulaminu Studia
            </li>
            <li>
              <FaRegSquare color="#fa4603" size="1rem"/>Sprawdzenie dojazdu do Studia
            </li>
          </ul>
        </div>
      </section>

    </LayoutGlobal>
  )
}